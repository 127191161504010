.game-board {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0;
    width: fit-content;
    position: relative;
    /*padding: var(--cell-size);*/
    padding: 50px;
  }
  