.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: hsl(0, 0%, 0%);
  height: 9vh;
  width: 100%;
  font-size: 110%;
  position: relative;
}

.logo {
  height: 100%; /* Set the height of the image to 100% of the li item */
  object-fit: contain; /* This will ensure that the image maintains its aspect ratio while fitting within the given dimensions */
  padding: 10px;
}

.nav-links li:hover::before,
.nav-links li.active::before,
.nav-links li:hover::after,
.nav-links li.active::after {
  content: "";
  position: absolute;
  background-color: #166bb1;
  /* width: 2px; */
  box-shadow: 0 0 0 1px #1f81dd;
  z-index: 1;
  height: 100%;
}

.nav-links li:hover::before,
.nav-links li.active::before {
  left: 0;
}

.nav-links li:hover::after,
.nav-links li.active::after {
  right: 0;
}

.nav-links .light-source-effect::before,
.nav-links .light-source-effect::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0; /* set opacity to 0 initially */
  transition: opacity 0.5s; /* animate opacity */
}

.nav-links .light-source-effect::before {
  background-image: radial-gradient(circle at top left, #103C8F 1%, transparent 50%);
}

.nav-links .light-source-effect::after {
  background-image: radial-gradient(circle at bottom right, #103C8F 1%, transparent 50%);
}

.nav-links .light-source-effect.active::before,
.nav-links .light-source-effect.active::after {
  opacity: 1; /* set opacity to 1 when active */
}

.light-source-effect {
  display: flex; 
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0px;
  justify-content: center;
}

.nav-links,
.auth-links {
  display: flex;
  height: 100%;
}

.nav-links ul,
.auth-links ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  align-items: center; 
  margin: 0;
  height: 100%;
}

.nav-links li {
  flex-shrink: 1; /* This will allow the li items to shrink if necessary */
  flex-grow: 1; /* This will allow the li items to grow if there is space available */
}

.nav-links li,
.auth-links li {
  display: flex; 
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0px;
  justify-content: center;
}

.nav-links a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  padding-left: 30px;
  padding-right: 30px;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

.auth-links a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  padding-left: 15px;
  padding-right: 30px;
  text-decoration: none;
  position: relative;
  z-index: 1;
}

/*Dropdown*/

.username-wrapper {
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
}

.username-wrapper::before {
  content: '';
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  height: 5px;
  z-index: 1;
}

.login-icon {
  height: 40px; 
  object-fit: contain; 
}

.user-dropdown {
  display: flex; /* Change from inline-block to flex */
  justify-content: center; /* Add this line */
  align-items: center; /* Add this line */
  cursor: pointer;
}

.dropdown-wrapper {
  position: absolute;
  right: 0;
  top: 100%;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  min-width: 200px;
  z-index: 1;
  flex-direction: column;
}

.dropdown-content a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 20px 0px;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-content a:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.user-dropdown:hover .dropdown-content {
  display: flex;
}

.mobile-logout {
  display: none;
}

@media screen and (max-width: 600px) {
  .mobile-logout {
    display: flex;
  }
}
