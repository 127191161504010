.position-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px;
  margin: 1px;
  background-image: linear-gradient(to right, rgb(24, 24, 24), #312f31);
  border-radius: 5px;
  cursor: pointer;
}

.position-item:hover {
background: rgb(24, 24, 24);
/* background-image: linear-gradient(to right, #525252, rgb(15, 15, 15)); */
}

.position-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%
}

.position-details>div {
  margin-bottom: 3px;
}

.win-bar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.win-bars {
  width: 80%; /* Set a fixed width for the win-bars */
  height: 10px;
  background-color: #ccc;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.win-bar {
  position: absolute;
  height: 100%;
  border-radius: 1px;
}
