.ranking-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.ranking-page a {
  color: inherit;
  text-decoration: none;
}

.ranking-page button {
  margin: 10px;
}

.ranking-page .games-table td {
  padding: 0px;
}

.ranking-user-wrapper {
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .games-table {
      width: 100%;
      left: 0;
  }

  .ranking-page .games-table th,
  .ranking-page .games-table td {
      padding-left: 0px;
      padding-right: 0px;
  }
}