.quadrant {
    display: flex;
    flex-wrap: wrap;
    width: calc(3 * var(--cell-size));
    height: calc(3 * var(--cell-size));
    background-size: cover;
    position: relative;
  }
  
  .cell {
    width: var(--cell-size);
    height: var(--cell-size);
    border: 0px solid #ccc;
    box-sizing: border-box;
  }
  
  :root {
    --cell-size: 100px;
  }
  
  .quadrant-wrapper {
    position: relative;
  }

  .marbles-container {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  