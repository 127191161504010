* {
  box-sizing: border-box;
}
 
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
 
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-family: 'Montserrat', sans-serif;
}
 
 
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
 
.content {
  flex-grow: 1;
  height: 91vh;
}
 
body {
  /* Background of the whole website */
  background-color: #312f31;
  background-attachment: fixed;
}
 
/* Hide scrollbar */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Show scrollbar on hover */
*:hover::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 3px;
}

/* Non-Webkit Browsers */

* {
  scrollbar-width: 6px;
  scrollbar-color: transparent #f5f5f5;
}

*:hover {
  scrollbar-color: #c1c1c1 #f5f5f5;
}

 
button {
  padding: 13px 20px;
  background-color: #103C8F;
  color: white;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  /* Add a transition for a smoother hover effect */
  transition: background-color 0.4s;
}
 
button:hover {
  background-color: #1F75C3;
}
 
.centerHome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
 
/* LOGIN PAGE CSS */
 
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(22, 22, 22);
  width: 500px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  text-align: center;
}
 
.login-page h2 {
  padding: 5px;
}
 
.login-unit input {
  font-size: 14px;
  margin-left: 5px;
}
 
.login-unit {
  padding-bottom: 30px;
}
 
/* SIGNUP PAGE CSS */
 
.signup-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(22, 22, 22);
  width: 500px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  text-align: center;
}
 
.signup-page h2 {
  padding: 5px;
}