.filter-options {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
}
 
.filter-container input {
  font-size: 16px;
}
 
.labeled-input {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  padding: 5%;
  gap: 10px;
}
 
input {
  border: none;
  outline: none;
  height: 35px;
  border-radius: 4px;
}
 
.upper-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 35%; */
}
 
.middle-filter {
  height: 35%;
}
 
.lower-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 30%;
}
 
.filter-options input {
  background-color: black;
  color: white;
}
 
.color-button {
  background-color: #1F75C3;
  color: white;
  border: none;
  margin: 0 0.5em;
  transition: background-color 0.4s;
}
 
.color-button:hover {
  background: #103C8F;
}
 
.color-button.active {
  background: #103C8F;
}
 
.circle {
  height: 1em;
  width: 1em;
  border-radius: 50%;
  display: inline-block;
}
 
.white-circle {
  background-color: white;
}
 
.black-circle {
  background-color: black;
}
 
.rating-range input{
  width: 20%;
}
 
.consider-symmetrical {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
 
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
 
input[type="number"] {
    -moz-appearance: textfield;
}