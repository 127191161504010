.analysis {
    display: flex;
    justify-content: center;
    height: 100%;
}
 
.filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    background-color: #141414;
    position: relative;
}
 
.game-board-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    padding-top: 5%;
}
 
.positions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 30%;
}
 
.next-positions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-height: 800px;
    /* Adjust this value based on the game board height */
    overflow-y: auto;
    border-radius: 5px;
    width: 80%;
    height: 90%;
}
 
.positions-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    font-size: 30px;
}
 
.arrow-buttons {
    display: flex;
    justify-content: center;
    padding: 5%;
    gap: 10%;
    overflow: hidden;
}
 
.arrow-button {
    background-color: #181818;
    border: none;
    border-radius: 3px;
    color: #ffffff;
    cursor: pointer;
    font-size: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: background-color 0.2s;
    width: 100px;
    height: 50px;
}
 
.arrow-button:hover {
    background-color: black;
}




@media screen and (max-width: 600px) {
    .analysis {
        flex-direction: column;
    }

    .filter-container {
        width: 100%;
    }

    .game-board-container {
        width: 100%;
    }

    .positions-container {
        width: 100%;
    }
}