.lobby-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

/* Left in file ChatBox.js */

/* Middle */
.middle-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  /* Hide the horizontal scrollbar */
}

.middle-content {
  width: 100%;
  /* Ensure middle-content takes the full width of middle-container */
  display: flex;
  flex-direction: column;
  height: 100%;
}

.middle-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
}

.user-list-wrapper {
  position: relative;
  overflow-y: auto;
  padding-bottom: 0%;
}

.user-list-wrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #312f31);
  pointer-events: none;
}

.user-list {
  list-style-type: none;
  padding: 0;
  height: 100%;
  overflow-y: auto;
  max-width: 75%;
  /* Limit the width of the user list items */
  min-width: 200px;
  /* Set a minimum width for the user list items */
  margin: 0 auto;
  /* Center the user list items */
}

.user-list:hover {
  overflow-y: auto;
}


.user-list li {
  position: relative;
  background-color: #000000;
  cursor: pointer;
  margin-bottom: -1px;
  padding: 7px;
  padding-right: 30px;
  margin: 4px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  min-height: 50px;
}

#empty-item {
  background-color: transparent;
  height: 100px;
  cursor: auto;
}

#empty-item:hover {
  border-image: none;
  background-image: none;
}

.user-list li:hover {
  border-image: linear-gradient(to left, #1F75C3, transparent) 1;
  background-image: radial-gradient(circle at center, rgba(31, 117, 195, 0.5) 0%, transparent 30%);
  background-size: 200% 200%;
}

.user-wrapper {
  display: flex;
  align-items: center;
}

.icon {
  height: 70px;
  object-fit: contain;
  /* This will ensure that the image maintains its aspect ratio while fitting within the given dimensions */
  padding: 10px;
}

.button-container-wrapper {
  height: 23%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  align-items: center;
  /* Center the content horizontally */
  padding-bottom: 15px;
}

.button-container-wrapper h1 {
  flex-shrink: 0;
}


@keyframes moving-light {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.button-container {
  height: 80%;
  width: 73%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  border-radius: 5px;
  background-color: #131213;
  padding: 20px;
  opacity: 0.5;
}

.incoming-challenge-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.incoming-challenge-container button {
  background-color: #1F75C3;
}

.incoming-challenge-container button:hover {
  background-color: #103C8F;
}

.challenge-buttons button {
  background-color: #1F75C3;
}

.challenge-buttons button:hover {
  background-color: #103C8F;
}

.challenge-buttons button.reject-button {
  background-color: black;
}

.button-container.challenge-active {
  opacity: 1;
  border: 1px solid #1F75C3;
  background-image: radial-gradient(circle at center, rgba(31, 117, 195, 0.5) 0%, transparent 100%);
  background-size: 200% 200%;
  animation: moving-light 3s linear infinite;
}

.lobby-container button.reject-button {
  background-color: black;
}

.challenge-buttons {
  display: flex;
  gap: 10px;
}

/* Right */
.right-container {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 30px;
  box-sizing: border-box;
  background-color: rgb(26, 25, 25);
}

@media screen and (max-width: 600px) {
  .user-list li {
    overflow-x: scroll;
    overflow: auto;
  }
}