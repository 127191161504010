:root {
    --hover-color: #3098f8;
}  
 
.fixed-size-button img {
    width: 50%;
    height: 50%;
}
 
.choose-time-wrapper {
    display: flex;
    align-items: center;
    gap: 7px;
}
 
.choose-time-wrapper .fixed-size-button {
    width: 50px;
    height: 50px;
    padding: 0;
    color: white;
}
 
.choose-time-wrapper .fixed-size-button.color0:hover { background-color: var(--hover-color); }
.choose-time-wrapper .fixed-size-button.color1:hover { background-color: var(--hover-color); }
.choose-time-wrapper .fixed-size-button.color2:hover { background-color: var(--hover-color); }
.choose-time-wrapper .fixed-size-button.color3:hover { background-color: var(--hover-color); }
.choose-time-wrapper .fixed-size-button.color4:hover { background-color: var(--hover-color); }
.choose-time-wrapper .fixed-size-button.color5:hover { background-color: var(--hover-color); }
.choose-time-wrapper .fixed-size-button.color6:hover { background-color: var(--hover-color); }
 
/* Time-button colors */
.choose-time-wrapper .fixed-size-button.color0 { background-color: #082E61; }
.choose-time-wrapper .fixed-size-button.color1 { background-color: #0A3C7D; }
.choose-time-wrapper .fixed-size-button.color2 { background-color: #0C4A99; }
.choose-time-wrapper .fixed-size-button.color3 { background-color: #0E58B5; }
.choose-time-wrapper .fixed-size-button.color4 { background-color: #1066D1; }
.choose-time-wrapper .fixed-size-button.color5 { background-color: #1284ED; }
.choose-time-wrapper .fixed-size-button.color6 { background-color: #14A2FF; }