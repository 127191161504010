.games-table {
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  width: 70%;
  color: #fff;
  background-color: #202020f3;
  border-radius: 15px;
  margin-bottom: 10px;
}

.games-table thead {
  background-color: #161616;
}

.games-table th {
  background-color: #161616;
}

.games-table th,
.games-table td {
  padding: 10px;
  text-align: center;
}

.games-table tr:hover {
  background-color: #161616;
  cursor: pointer;
}

tbody tr:nth-child(even) {
  background-color: #1a1a1a;
}

.gamelist-username {
  display: flex;
  align-items: center;
}

.gamelist-username:hover {
  font-weight: bold;
}


@media screen and (max-width: 600px) {
  .games-table {
    width: 100%;
    left: 0;
  }

  .games-table th,
  .games-table td {
    padding: 0px;
  }
}