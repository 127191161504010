@media screen and (max-width: 600px) {
    :root {
        --cell-size: ${CELL_SIZE_MOBILE_CSS};
      }

    .content {
        height: auto;
        width: auto;
    }

    .lobby-container {
        flex-direction: column;
    }

    .chat-container.visible,
    .chat-container.hidden {
        width: 100%;
    }

    .chat-area {
        height: 150px;
    }

    .chatbox-divider {
        visibility: hidden;
        width: 0px;
        left: -50px;
    }

    .middle-container {
        width: 100%;
    }

    .user-list {
        margin: 0px;
        max-width: 100%;
        max-height: 300px;
    }

    .choose-time-wrapper .fixed-size-button {
        width: 30px;
        height: 30px;
    }

    .right-container {
        width: 100%;
    }

    .big-icon {
        max-width: 250px;
    }

    .topbar {
        overflow: scroll;
    }

}