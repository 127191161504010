.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2, h3 {
  margin-bottom: 20px;
}

.recent-games {
  width: 100%;
  max-width: 800px;
}

.game-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 30px;
}

.game-pagination span {
  margin: 0 10px;
}

@media (max-width: 480px) {
  .profile-stats {
    grid-template-columns: 1fr;
  }
}

button:disabled {
  background-color: #cccccc; /* Set the background color for disabled buttons */
  color: #666666; /* Set the text color for disabled buttons */
}


.username-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.username-container h2 {
  font-size: 60px;
  margin: 0px;
}

.general-data-container {
  width: 60%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2%;
  padding-right: 2%;
  gap: 10px;
  background-color: #202020;
  font-size: 20px;
  border-radius: 7px;
}

.general-data-container table {
  width: 100%;
} 

.data-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.data-item strong {
  margin-right: 10px;
}

.profile-rating {
  color: #1F75C3;
  font-size: 30px;
  display: flex;
  align-items: center;
}

.profile-rating strong {
  color: white;
  font-size: 20px;
  margin-right: 10px;
}

.profile-icon {
  height: 180px; 
  object-fit: contain; 
  padding: 30px;
}

@media screen and (max-width: 600px) {
  .profile-page {
    align-items: flex-start;
  }
  .general-data-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 10px;
  }
  
  .profile-icon {
    height: 130px;
  }

  .extra-icon {
    display: none;
  }

  .username-container h2 {
    font-size: 18px;
  }
}